'use client'

import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'

const googleSendEvent = (event: string, ntmData: any, callback?: () => void) => {
    const { CookieFirst }: any = window

    if (CookieFirst) {
        if (true === CookieFirst.consent?.advertising) {
            console.log({ event, ...ntmData })
            sendGTMEvent({ event, ...ntmData });
            sendGAEvent('event', event, ntmData);
        }

        if (callback) callback();
    }
}

export default googleSendEvent
