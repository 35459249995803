import type { ModalProps } from '.';

//import React, { useState } from 'react';
import { default as BsModal } from 'react-bootstrap/Modal';

const Modal = ({ children, title, buttonText = 'Öffnen' }: ModalProps) => {
    // const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    {/* onClick={handleShow} */ }
    return (
        <>
            <a href={'#'} className={'d-inline-block p-0 m-0'}>
                {buttonText}
            </a>

            <BsModal
                //   show={show}
                //   onHide={handleClose}
                backdrop={'static'}
                keyboard={false}
            >
                {title && (
                    <BsModal.Header closeButton>
                        <BsModal.Title>{title}</BsModal.Title>
                    </BsModal.Header>
                )}
                
                <BsModal.Body className={'modal-body'}>
                    {children}
                </BsModal.Body>
            </BsModal>
        </>
    )
}

export default Modal
