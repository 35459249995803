'use client'

import { useEffect, useState } from 'react';
import type { RouterEventsProps } from '.';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';

const RouterEvents = ({ }: RouterEventsProps) => {
    const [lastPathName, setLastPathName] = useState<string>()

    const pathname = usePathname();
    const { update } = useSession()

    useEffect(() => {
        if (lastPathName === pathname) return

        update({ trigger: 'blubb' })
        setLastPathName(pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}

export default RouterEvents
