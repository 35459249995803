'use client'

import { useEffect, ReactNode, createContext, useContext, useState } from 'react';
import { signIn, useSession } from 'next-auth/react'
import { useLocale } from 'next-intl';

const AnonymousSessionContext = createContext<any>(undefined)

export function useSessionContext() {
    return useContext(AnonymousSessionContext)
}

export default function AnonymousSessionProvider({
    children
}: {
    children: ReactNode
}) {
    let [displayLoggedOutInfo, setDisplayLoggedOutInfo] = useState<boolean>(false)

    const { status } = useSession()
    const locale = useLocale()

    useEffect(() => {
        if (status === 'unauthenticated' && undefined !== locale) {
            signIn('anonymous', { redirect: false }, { locale });
        }
    }, [status, locale]);

    return (
        <AnonymousSessionContext.Provider value={{ displayLoggedOutInfo, setDisplayLoggedOutInfo }}>
            {children}
        </AnonymousSessionContext.Provider>
    );
}