'use client'

import { ReactElement, cloneElement } from 'react';
import useBreakpoint from '@hooks/useBreakpoint';
import closeMegaMenu from '@utils/close-mega-menu';

const Anchor = ({ children }: { children: ReactElement }) => {
    const breakpoint = useBreakpoint()

    return (
        cloneElement(children, { ...((!['xs', 'sm'].includes(breakpoint) || 0 === children.props.length) && { onClick: closeMegaMenu }) })
    )
}

export default Anchor
