'use client'
import React, { CSSProperties } from 'react'

import { LiveChatLoaderProvider, useChat } from 'react-live-chat-loader'

const styles: {
    container: CSSProperties
    button: CSSProperties
    icon: CSSProperties
} = {
    container: {
        zIndex: 2147483648, // one more than provider
        position: 'fixed',
        border: '0',
        width: '64px',
        height: '64px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 8px 20px 0px',
        left: 'auto'
    },
    button: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '64px',
        minWidth: '64px',
        height: '64px',
        minHeight: '64px',
        padding: '0',
        cursor: 'pointer',
        border: '0'
    },
    icon: {
        fontSize: '40px'
    }
}

interface Props {
    color?: string
    backgroundColor?: string
    position?: string
    vOffset?: string
    hOffset?: string
    style?: string
}

const Userlike = ({
    color = 'white',
    backgroundColor = '#0d8cff',
    position = 'right',
    vOffset = 'calc(0% + 20px)',
    hOffset = 'calc(0% + 24px)',
    style = 'round'
}: Props): JSX.Element | null => {
    const [state, loadChat] = useChat({ loadWhenIdle: true })
    /*
    const positionStyles = {
        bottom: vOffset,
        right: position === 'left' ? 'auto' : hOffset,
        left: position === 'right' ? 'auto' : hOffset
    }
    const shapeStyle = {
        borderRadius: style === 'round' ? '50%' : '0'
    }

    if (state === 'complete') {
        return null
    }
*/
    return (
        <LiveChatLoaderProvider providerKey={process.env.NEXT_PUBLIC_LIVE_CHAT_PROVIDER_KEY ?? ''}  provider="userlike">
        <></>
{/* 
            <div style={{ ...styles.container, ...positionStyles, ...shapeStyle }}>
                <button
                    role="button"
                    aria-label="Load Chat"
                    aria-busy="true"
                    aria-live="polite"
                    onClick={() => loadChat({ open: true })}
                    onMouseEnter={() => loadChat({ open: false })}
                    style={{
                        ...styles.button,
                        ...shapeStyle,
                        backgroundColor
                    }}
                >
                    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" style={{ ...styles.icon, color }}>
                        <path d="M27.8 8.1C25 4.4 20.5 2 15.5 2 7 2 0 9 0 17.5c0 2.6.7 5.1 1.8 7.3.2.2.2.6.2.9l-1 5.1c-.1.7.5 1.3 1.2 1.2l5.6-1.1c.2.1.3.2.5.3 2.2 1.2 4.7 1.8 7.3 1.8 1.2 0 2.5-.2 3.6-.4-2.6-2.7-4.1-6.3-4.1-10.1C15 15.1 20.6 9 27.8 8.1z" fill="currentColor"></path>
                        <path d="M38.4 28c.1-.3.3-.5.3-.6.8-1.5 1.2-3.2 1.2-4.9 0-5.6-4.5-10.2-10-10.5h-.5C23.7 12 19 16.7 19 22.5c0 3.4 1.7 6.5 4.2 8.4 1.8 1.3 3.9 2.1 6.3 2.1 1.8 0 3.4-.5 4.9-1.2.1 0 .3-.1.6-.3l2.8.5h.2c.6 0 1.1-.6 1-1.2l-.6-2.8z" fill="currentColor"></path>
                    </svg>
                </button>
            </div>
*/}
        </LiveChatLoaderProvider>
    )
}

export default Userlike