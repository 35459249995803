'use client'

import type { DropdownAccountProps } from "."

import { signOut, useSession } from "next-auth/react"

import Dropdown from "react-bootstrap/Dropdown"
import DropdownToggle from "react-bootstrap/DropdownToggle"
import DropdownMenu from "react-bootstrap/DropdownMenu"
import DropdownItem from "react-bootstrap/DropdownItem"
import DropdownDivider from "react-bootstrap/DropdownDivider"

const DropdownAccount = ({ labels }: DropdownAccountProps) => {
    const t: any = (key: string) => labels?.[key] ?? key
    const { status, data: session } = useSession()

    return (
        <Dropdown align={'end'}>
            <DropdownToggle size={'sm'} variant={'transparent'} aria-label={t('button')} className={'d-inline-flex  flex-column align-items-center  border-0  fs-8  p-0  m-0  mx-1  icon  icon--user  icon--size__24'}>
                <span className={'d-none  d-md-block  fs-10  fw-extrabold  text-uppercase'}>{t('button')}</span>
                {('authenticated' === status && false === session?.user?.anonym) && (
                    <span className={'position-absolute  d-inline-block  top-0  start-100  start-md-auto  end-md-0  translate-middle  rounded-circle  bg-success  button--active'} />
                )}
            </DropdownToggle>
            <DropdownMenu className={'bg-white'} >
                {('authenticated' === status && false === session?.user?.anonym) && (
                    <>
                        <DropdownItem key={'account'} href={'/account'} className={'fs-8'}>
                            {t('overview')}
                        </DropdownItem>
                        <DropdownItem key={'orders'} href={'/account/orders'} className={'fs-8'}>
                            {t('orders')}
                        </DropdownItem>
                        <DropdownItem key={'address'} href={'/address'} className={'fs-8'}>
                            {t('addresses')}
                        </DropdownItem>
                        <DropdownItem key={'wishlist'} href={'/note'} className={'fs-8'}>
                            {t('wishlist')}
                        </DropdownItem>
                        <DropdownDivider />
                        <DropdownItem key={'logout'} onClick={() => signOut({ callbackUrl: '/account/logout' })} className={'fs-8'}>
                            {t('logout')}
                        </DropdownItem>
                    </>
                )}
                {('authenticated' !== status || true === session?.user?.anonym) && (
                    <>
                        <DropdownItem key={'login'} href={'/account/login'} className={'fs-8'}>
                            {t('login')}
                        </DropdownItem>
                        <DropdownItem key={'register'} href={'/account/register'} className={'fs-8'}>
                            {t('register')}
                        </DropdownItem>
                    </>
                )}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownAccount
