'use client'

import { handleAddPromotionToCart } from "@app/actions";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { TypeOptions, toast } from "react-toastify";
import errorsJson from '../../../../errors'
import { useCartContext } from "@context/cart-provider";

const Coupon = () => {
    const searchParams = useSearchParams();
    const coupon = searchParams.get('coupon')

    const { cart, setCart } = useCartContext()
    const [addedCoupons, setAddedCoupons] = useState<string[]>([])

    const t = (key: any, params?: any) => {
        let error = (errorsJson as any)[key] ?? ''
        Object.keys(params ?? {}).forEach(p => {
            const reg = new RegExp(`\\{\\{\\s*${p}\\s*\\}\\}`, 'g')
            error = error.replace(reg, params[p])
        })
        return error
    }

    const getMessage = (error: any): { message: string, type: TypeOptions } => {
        const key = error.messageKey?.replace(error.id, '')
        switch (key) {
            case 'promotion-discount-added':
                return { message: t('cart_discount', { name: error.message.replace(/Discount /, '').replace(/ has been added/, '') }), type: 'success' }
            case 'promotion-not-found':
                return { message: t(error.messageKey, { promotionCode: error.promotionCode }), type: 'error' }
            case 'promotion-not-eligible':
            case 'promotion-excluded':
                return { message: t(error.messageKey, { name: error.name }), type: 'error' }
            default:
                return { message: t('promotion-not-found', { promotionCode: error.promotionCode }), type: 'error' }
        }
    }


    const addPromotionToCart = async (code: string) => {
        await handleAddPromotionToCart([code]).then(({ errors, ...cart }: any) => {
            const keys = Object.keys(errors)

            if (errors && Array.isArray(keys) && keys.length) {
                Object.keys(errors).forEach((key: string) => {
                    const { message, type }: { message: string, type: TypeOptions } = getMessage(errors[key])
                    toast(message, { type })
                })
            }

            setCart({ ...cart, errors })
        })
    }

    useEffect(() => {
        const strCoupon = coupon?.toString()

        if (!strCoupon || addedCoupons.includes(strCoupon) || !Array.isArray(cart?.extensions?.cartPromotions?.addedCodes)) return
        if (cart.extensions.cartPromotions.addedCodes.includes(strCoupon)) return

        addPromotionToCart(strCoupon)
        setAddedCoupons((addedCoupons) => ([...addedCoupons, strCoupon]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupon, cart])

    return null
}

export default Coupon

