'use client'

import { useCartContext } from "@context/cart-provider"
import ActionBox from "../action-box"
import t from "@/utils/translation-faker"
import { useEffect, useState } from "react"

const NewCustomerCoupon = () => {
    const { cart } = useCartContext()

    const [showInfo, setShowInfo] = useState<boolean>(false)

    useEffect(() => {
        setShowInfo(() => {
            const items = cart?.lineItems?.filter(({ type, referencedId }: { type: string, referencedId: string }) => 'promotion' === type && 'hallo15' === referencedId.toLocaleLowerCase())
            return 0 === items?.length
        })
    }, [cart])

    return (showInfo && (
        <ActionBox hasIcon={false} hasSpan={false} variant={'primary'} className={'my-1  py-2  align-items-start  text-dark  border-orange-500'}>
            <span dangerouslySetInnerHTML={{ __html: t('offcanvas_new_customer_coupon') }}></span>
        </ActionBox>
    ))
}

export default NewCustomerCoupon
