'use client';

import React from "react";
import { usePathname } from "next/navigation"

const TopNavItemClient = ({ children }: { children: React.ReactElement }) => {

    const pathNames = usePathname()
    const [_, pathName] = pathNames.split('/')
    const [__, href] = children?.props?.children?.props?.href?.split('/')

    return (
        <>{React.cloneElement(children, {
            className: `${children.props.className}${href === pathName ? ' is-active' : ''}`,
            onClick: () => {
                var element = document.getElementById('all-products') as HTMLInputElement;
                if (element && true === element.checked) {
                    element.click();
                }
            }
        })}</>
    )
}

export default TopNavItemClient
