'use client'

import type { DropdownListsProps } from "."

import Dropdown from "react-bootstrap/Dropdown"
import DropdownToggle from "react-bootstrap/DropdownToggle"
import DropdownMenu from "react-bootstrap/DropdownMenu"
import DropdownItem from "react-bootstrap/DropdownItem"

import { useCompareContext } from "@context/compare"
import { useWishlistContext } from "@context/wishlist"

const DropdownLists = ({ labels }: DropdownListsProps) => {
    const { products: compareProducts, toggleShow } = useCompareContext()
    const { products: wishlistProducts } = useWishlistContext()

    const t: any = (str: string) => labels[str] ?? str


    const renderBubble = (show: boolean) => show && <span className={'position-absolute  d-inline-block  translate-middle top-50  start-0  ms-2  rounded-circle  bg-success  button--active'} />

    return (
        <Dropdown align={'end'}>
            <DropdownToggle size={'sm'} variant={'transparent'} aria-label={t('toggle')} className={'d-inline-flex  flex-column align-items-center  border-0  fs-8  p-0  m-0  mx-1  icon  icon--list  icon--size__24'}>
                <span className={'d-none  d-md-block  fs-10  fw-extrabold  text-uppercase'}>{t('toggle')}</span>
                {(0 < Object.keys(compareProducts).length || 0 < Object.keys(wishlistProducts).length) && (
                    <span className={'position-absolute  d-inline-block  top-0  start-100  start-md-auto  end-md-0  translate-middle  rounded-circle  bg-success  button--active'} />
                )}
            </DropdownToggle>
            <DropdownMenu className={'bg-white'}>
                <DropdownItem className={'position-relative  fs-8'} href={'/note'}>
                    {renderBubble(0 < Object.keys(wishlistProducts).length)}
                    {t('wishlist')}
                </DropdownItem>

                <DropdownItem className={'position-relative  fs-8'} onClick={toggleShow}>
                    {renderBubble(0 < Object.keys(compareProducts).length)}
                    {t('compare')}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>

    )
}

export default DropdownLists
