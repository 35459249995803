'use client';

import type { OffcanvasCompareProps } from '.';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import FormText from 'react-bootstrap/FormText';

import { useCompareContext } from '@context/compare';
import { useEffect, useState } from 'react';
import MultiCarousel from '@components/molecules/multi-carousel';
import Card from '@components/molecules/card';
import Image from '@components/atoms/image';
import Link from '@components/atoms/link';
import ProductBadges from '@components/molecules/product-badges/ProductBadgesClient';
import Table from 'react-bootstrap/Table';
import TableBody from '@components/atoms/table-body';
import TableRow from '@components/atoms/table-row';
import TableCell from '@components/atoms/table-cell';
import Price from "@components/atoms/price/Client";
import Button from '@/components/atoms/button';
import AddToCartButton from '@components/molecules/add-to-cart';
import Delivery from '../delivery';
import { useLocale } from 'next-intl';

const OffcanvasCompare = ({ label: { title } }: OffcanvasCompareProps) => {
    const locale = useLocale()
    const { products: compareProducts, show, setShow, removeProduct } = useCompareContext()

    const [products, setProducts] = useState<any[]>([])

    const handleClose = () => setShow(false);

    useEffect(() => {
        const ids = Object.keys(compareProducts)
        if (0 >= ids.length) return

        const fetchProducts = async (ids: string[]) => {
            if (0 < ids.length) {
                const res = await fetch('/api/product?locale=' + locale, {
                    method: "POST",
                    body: JSON.stringify({
                        ids: ids,
                        includes: {
                            product: [
                                'id',
                                'parentId',
                                'productNumber',
                                'name',
                                'translated',
                                'cover',
                                'markAsTopseller',
                                'isNew',
                                'isCloseout',
                                'customFields',
                                'sortedProperties',
                                'calculatedCheapestPrice',
                                'taxId',
                                'deliveryTime',
                                'minPurchase',
                                'availableStock'
                            ],
                            property_group: ['name', 'options', 'translated'],
                            property_group_option: ['name', 'position', 'group', 'translated'],
                            media: ['url', 'alt', 'name', 'translated'],
                        },
                        associations: {
                            properties: {
                                associations: {
                                    group: {},
                                },
                            },
                        },

                    })
                })
                const { elements } = await res.json()

                setProducts(elements)
            }
        }
        fetchProducts(ids)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareProducts])

    return (
        <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={true} style={{ width: '95vw' }}>
            <Offcanvas.Header className={'py-4 bg-gray-200'}>
                <Offcanvas.Title>
                    <span onClick={handleClose} className={'d-flex  fw-bold  fs-6  icon  icon--chevron-left  icon--size__24  icon--valign__middle  icon--secondary  text-decoration-none  cursor--pointer'}>
                        {title}
                    </span>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={'flex-grow  px-5'}>
                {0 < Object.keys(compareProducts).length ? (
                    <MultiCarousel
                        key={'compare'}
                        className={'h-100'}
                        slideClass={'h-100'}
                        navigationOutside={true}
                        spaceBetween={24}
                        breakpoints={{
                            0: { slidesPerView: 1, },
                            576: { slidesPerView: 1, },
                            768: { slidesPerView: 2, },
                            992: { slidesPerView: 2, },
                            1200: { slidesPerView: 3, },
                            1500: { slidesPerView: 4, },
                            1800: { slidesPerView: 5, },
                        }}
                    >
                        {products.map(({
                            id,
                            parentId,
                            productNumber,
                            translated: { name, customFields },
                            sortedProperties,
                            calculatedCheapestPrice,
                            seoUrls,
                            cover,
                            markAsTopseller,
                            isNew,
                            isCloseout,
                            taxId,
                            deliveryTime,
                            minPurchase,
                            availableStock
                        }: any) => (
                            <Card
                                key={id}
                                loading={false}
                                className={'overflow-hidden  overflow-y-scroll'}
                                footer={
                                    <div className={'my-2'}>
                                        <div className={'d-flex  product-card-pricing-row  mb-3'} >
                                            <div className='flex-fill'>
                                                {((false === customFields?.product_price_upon_request && true !== customFields?.product_rental_offer_request) && 0 < calculatedCheapestPrice?.unitPrice) ? (
                                                    <>
                                                        <span className={'H5-Headline-Default'} >
                                                            {'ab'}{' '}<Price
                                                                unitPrice={calculatedCheapestPrice.unitPrice}
                                                                purchaseUnit={calculatedCheapestPrice.referencePrice?.purchaseUnit ?? calculatedCheapestPrice.purchaseUnit ?? 1}
                                                                referenceUnit={calculatedCheapestPrice.referencePrice?.referenceUnit ?? calculatedCheapestPrice.referenceUnit ?? 1}
                                                                taxId={taxId}
                                                            />
                                                        </span>{' / '}
                                                        <span className={'fw-semibold  fs-8'}>
                                                            {'pro Einheit'}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className={'H5-Headline-Default'}>
                                                        {true === customFields?.product_rental_offer_request ? 'Mietangebot auf Anfrage' : 'Preis auf Anfrage'}
                                                    </span>
                                                )}
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Delivery deliveryTime={deliveryTime} minPurchase={minPurchase} availableStock={availableStock} variant={'icon'} />
                                            </div>
                                        </div>
                                        {((false === customFields?.product_price_upon_request && true !== customFields?.product_rental_offer_request) && 0 < calculatedCheapestPrice?.unitPrice) ? (
                                            <AddToCartButton
                                                productId={id}
                                                parentId={parentId}
                                                purchaseUnit={calculatedCheapestPrice.referencePrice?.purchaseUnit ?? calculatedCheapestPrice.purchaseUnit ?? 1}
                                                referenceUnit={calculatedCheapestPrice.referencePrice?.referenceUnit ?? calculatedCheapestPrice.referenceUnit ?? 1}
                                                quantity={1}
                                                productNumber={productNumber}
                                                deliveryTime={deliveryTime}
                                                seoUrls={seoUrls}
                                                className={'w-100'}
                                                label={'In den Warenkorb'}
                                            />
                                        ) : (
                                            <Link
                                                href={(true === customFields?.product_rental_offer_request ? '/mietanfrage-fuer-maschinen/' : '/produktanfrage-formular/') + productNumber}
                                                className={'w-100  py-3  btn  btn-secondary'}
                                            >
                                                {true === customFields?.product_rental_offer_request ? 'Mietangebot anfragen' : 'Preis auf Anfrage'}
                                            </Link>
                                        )}
                                        <Button
                                            className={'w-100  mt-2  icon  icon--trash  icon--size__24px'}
                                            variant={'outline-secondary'}
                                            onClick={() => removeProduct(id)}
                                        >
                                            {'Entfernen'}
                                        </Button>
                                    </div>
                                }
                            >
                                <div className={'position-relative'}>
                                    <Link href={seoUrls?.[0]?.seoPathInfo} className={'position-relative  d-block  h-100  w-100  text-center  pb-2'}>
                                        <Image
                                            key={'compare_slider_' + id + '_cover_no_image'}
                                            src={cover.media?.url ?? '/no-image.png'}
                                            alt={cover.media?.alt ?? name}
                                            className={'object-fit-contain'}
                                            width={'327'}
                                            height={'249'}
                                            sizes={'327'}
                                        />
                                    </Link>
                                    <ProductBadges
                                        className={'d-flex flex-column gap-1 position-absolute top-0'}
                                        badgesWithHover={true}
                                        markAsTopseller={markAsTopseller}
                                        isNew={isNew}
                                        isCloseout={isCloseout}
                                        isPromotion={customFields?.product_promotion}
                                        tSignetIds={customFields?.product_environmental_signet ?? []}
                                        labels={{
                                            ["new"]: "NEU!",
                                            ["topseller"]: "Topseller",
                                            ["closeout"]: "Auslauf",
                                            ["promotion"]: "Aktion",
                                            ["eco"]: "Umweltfreundlich",
                                            ["badge_eco-1"]: "Biologisch abbaubar",
                                            ["badge_eco-2"]: "Industriell abbaubar",
                                            ["badge_eco-3"]: "aus nachwachsendem Rohstoff",
                                            ["badge_eco-4"]: "Recyclebar",
                                            ["badge_eco-5"]: "aus Recyclingmaterial",
                                            ["badge_eco-6"]: "reduzierter Materialeinsatz",
                                            ["badge_eco-8"]: "Wiederverwendbar",
                                            ["badge_eco-umweltsignets_vegan"]: "Vegan",
                                            ["badge_eco-umweltsignet_fsc"]: "FSC<sup>®</sup> C172961"
                                        }}
                                    />
                                </div>
                                <hr className={'mt-0  mx-n3'} />
                                <div className={'d-flex  flex-column  flex-fill'}>
                                    <Link href={seoUrls?.[0]?.seoPathInfo} className={'d-inline-block  w-100  flex-fill  fw-bold  fs-8  text-decoration-none'}>
                                        {name}
                                    </Link>
                                    {(customFields?.product_description_short) && (
                                        <div className={'product-card-description  fs-8  fw-semibold  text-gray-900  mb-3  mt-3'}>
                                            {customFields?.product_description_short.replace(/<[^>]*>?/gm, '')}
                                        </div>
                                    )}
                                    {(0 < sortedProperties?.length) && (
                                        <div className="product-detailed-information-table">
                                            <Table striped>
                                                <TableBody>
                                                    {sortedProperties.map(({ translated: { name }, options }: any, index: number | string) => (
                                                        <TableRow key={'product_detailed_info_row_' + name + index}>
                                                            <TableCell
                                                                className={`col-6 fs-8${index === sortedProperties.length - 1 ? ' border-bottom-0' : ''}`}
                                                                component={'th'}
                                                                key={'product_detailed_info_row_label_th_' + name + index}
                                                            >
                                                                {name}
                                                            </TableCell>
                                                            <TableCell
                                                                className={`col-6 fs-8${index === sortedProperties.length - 1 ? ' border-bottom-0' : ''}`}
                                                                component={'td'}
                                                                key={'product_detailed_info_row_value_td_' + name + index}
                                                            >
                                                                {options.map(({ translated: { name } }: any, index: number) => (
                                                                    <span key={'property_option_' + name}>{`${name}${index < options.length - 1 ? ', ' : ''}`}</span>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        ))}
                    </MultiCarousel>
                ) : (
                    <div>{'Keine Produkte gefunden'}</div>
                )}
            </Offcanvas.Body>
            <Container className="offcanvas-footer  pt-2  fw-semibold" fluid>
                <div className={'position-absolute  w-100  border-bottom'} style={{ top: -25, left: 0, height: 25, opacity: 0.1, backgroundImage: 'linear-gradient(rgba(122, 131, 133, 0), rgb(122, 131, 133))' }}></div>
                <div className={'d-flex flex-column  bg-white mb-2'}>
                    <FormText className={'text-gray-500  fs-9'}>{'*Alle Preise zzgl. Mehrwertsteuer und Versandkosten'}</FormText>
                    <FormText className={'text-gray-500  fs-9'}>{'*Abbildungen können vom Original abweichen'}</FormText>
                </div>
            </Container>
        </Offcanvas>
    )
}

export default OffcanvasCompare
