'use client'

import type { GTMParams } from '.';

import React, { useEffect, useState } from 'react';
import { GoogleTagManager } from '@next/third-parties/google'

const GTMPixelScript = ({ gtmId }: GTMParams) => {

    const [mounted, setMounted] = useState<boolean>(false)
    const [loadAllowed, setLoadAllowed] = useState<boolean>(false)

    useEffect(() => {

        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (false === mounted && true === consent?.advertising) {
                setLoadAllowed(true)
                setMounted(true)
            }
        }

        if (false === mounted) {
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    });

    return (true === loadAllowed && null !== gtmId) ? <GoogleTagManager key={'google-analytics-script'} gtmId={gtmId} /> : null

}

export default GTMPixelScript;
