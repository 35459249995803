'use client'

import { ReactElement, cloneElement, useEffect, useRef } from 'react';
import closeMegaMenu from '@utils/close-mega-menu';

const Wrapper = ({ children }: { children: ReactElement }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeMegaMenu()
            }
        }

        document.addEventListener('click', handleOutsideClick, false);

        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
        };
    }, []);

    return cloneElement(children, { ref: wrapperRef })
}

export default Wrapper
