'use client';

import useBreakpoint from "@/hooks/useBreakpoint";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react"

const TopNavClient = ({ children }: { children: React.ReactNode[] }) => {
    const [previous, setPrevious] = useState<any | undefined>(undefined)
    const [next, setNext] = useState<any | undefined>(undefined)

    const ref = useRef<HTMLUListElement>(null);

    const breakpoint = useBreakpoint()

    useEffect(() => {
        if (!ref?.current || ['xs', 'sm'].includes(breakpoint)) return;

        const observer = new IntersectionObserver((entries) => {
            setNext((next: any) => {
                if (undefined !== next) return next

                const _entries = [...entries].reverse()
                const _next = _entries.find(entry => (1 > entry.intersectionRatio && 0 < entry.intersectionRatio))

                return undefined !== next ? next : (true === _next?.isIntersecting ? _next?.target.nextSibling : _next?.target)
            })

            setPrevious((previous: any) => {
                if (undefined !== previous) return previous
            })
        }, {
            threshold: .25,
        });

        Array.from(ref.current.children).forEach((child: any) => {
            observer.observe(child)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (!['xs', 'sm'].includes(breakpoint) ? (
        <>
            {previous && (
                <span
                    onClick={() => {
                        previous.parentNode.scroll({ left: previous.offsetLeft - 10, behavior: 'smooth' })

                        setNext(next?.previousSibling ?? ref.current?.children[ref.current?.children.length - 1])
                        setPrevious(previous.previousSibling)
                    }}
                    className={classNames('position-absolute  d-none  d-md-inline-flex  fw-bold  text-white  bg-white  ms-2  icon  icon--chevron-left  icon--valign-middle  icon--black  z-1  cursor--pointer')}
                    style={{ left: '150px' }}
                ></span>
            )}
            <ul ref={ref} className={'position-relative  navbar-nav  navbar-nav__width  d-none  d-md-flex  flex-nowrap  py-2  overflow-hidden'}>
                {children}
            </ul>
            {next && (
                <span
                    onClick={() => {
                        next.scrollIntoView({ block: 'nearest', inline: 'end', behavior: 'smooth' })

                        setNext(next.nextSibling ?? null)
                        setPrevious(previous?.nextSibling ?? ref.current?.children[0])
                    }}
                    className={classNames('position-absolute  d-none  d-md-inline-flex  fw-bold  text-white  bg-white  end-0  pe-2  icon  icon--chevron-right  icon--valign-middle  icon--black  z-1  cursor--pointer')}
                ></span>
            )}
        </>
    ) : (
        null
    ))
}

export default TopNavClient
