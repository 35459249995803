// src/components/molecules/shpw-up-to-free-shipping/ShowUpToFreeShipping.js

import type { ShowUpToFreeShippingProps } from "."

import { useState } from "react"
import ActionBox from "@components/atoms/action-box"
import formatPrice from "@utils/format-price";
import classNames from "classnames"
import { useLocale } from "next-intl";

const ShowUpToFreeShipping = ({ positionPrice, prices, hasFreeShipping, labels, hasMargin = true }: ShowUpToFreeShippingProps) => {
    const locale = useLocale()

    const [internalHasFreeShipping] = useState<boolean>(hasFreeShipping)

    const t = (str: string) => labels?.[str] ?? str

    return ((Array.isArray(prices) && 0 === prices.length) ? (
        <ActionBox key={'action_up_to_shipping_free'} color={'#636464'} variant={'secondary'} className={classNames('my-1 py-2 align-items-start', { 'mb-3': hasMargin })} hasIcon={false}>
            <span className={'d-block'}>
                <span>{t(true === internalHasFreeShipping ? 'up_to_free_shipping_present' : 'up_to_free_shipping_reached')}</span >
            </span>
        </ActionBox>
    ) : (prices?.map(({ quantityStart }: { quantityStart: number }, index: number) =>
        <ActionBox key={'action_up_to_shipping_free_price_' + index} color={'#636464'} variant={'secondary'} className={classNames('my-1 py-2 align-items-start', { 'mb-3': hasMargin })} hasIcon={false}>
            <span className={'d-block'}>
                <span dangerouslySetInnerHTML={{ __html: t('up_to_free_shipping').replace('{ value }', formatPrice({ value: quantityStart - positionPrice, locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })) }} />
            </span >
        </ActionBox>
    )))
}

export default ShowUpToFreeShipping
