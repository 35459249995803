'use client'

import type { SessionInfoProps } from "."

import Button from "@components/atoms/button"
import LoginWrapper from "@components/molecules/login-wrapper"
import Form from "@components/atoms/form"
import FormGroup from "@components/molecules/form/form-group"
import FormLabel from "@components/atoms/form-label"
import FormControl from "@components/atoms/form-control"
import { useSessionContext } from "@/components/AnonymousSessionProvider"

const SessionInfo = ({ }: SessionInfoProps) => {
    const { displayLoggedOutInfo, setDisplayLoggedOutInfo } = useSessionContext()

    const handleHide = () => setDisplayLoggedOutInfo(false)

    return (displayLoggedOutInfo ? (
        <div className={'position-absolute  top-0  left-0  overflow-hidden  vw-100  vh-100  p-0  m-0'} style={{ zIndex: 1070 }}>
            <div className={'position-absolute  top-0  left-0  vw-100  vh-100  d-flex  align-items-center justify-content-center'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1 }}>
                <div className={'position-relative d-flex  flex-column  justify-content-between  shadow-sm  bg-white  p-4 m-2 sm-m-0 rounded-1 min-w-100'} style={{ maxWidth: 500, minHeight: 200, zIndex: 2 }}>
                    <h1 className={'H4-Headline-Default  mb-2'}>{'Abmeldung'}</h1>
                    <p className={'fs-8  mb-4'}>{'Wir haben Sie wegen Inaktivität zu Ihrer eigenen Sicherheit abgemeldet. Bitte melden sich sich erneut an, um auf alle Funktionen unseres Shops zugreifen zu können.'}</p>
                    <Button onClick={handleHide} variant={'secondary'} centered shadow className={'rounded-1'} >{'Weiter einkaufen'}</Button>
                    <hr />
                    <LoginWrapper>
                        <Form className={'mb-4 fs-8'}>
                            <FormGroup>
                                <FormLabel className={'fw-bold'}>{'E-Mail'}</FormLabel>
                                <FormControl
                                    id={'username_loginWrapper'}
                                    className={'fs-8 py-2 mb-2'}
                                    type={'text'}
                                    autoComplete={'email'}
                                    placeholder={'E-Mail'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={'fw-bold'}>{'Passwort'}</FormLabel>
                                <FormControl
                                    id={'password_loginWrapper'}
                                    className={'fs-8 px-2'}
                                    type={'password'}
                                    autoComplete={'current-password'}
                                    placeholder={'Passwort'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <div className="d-grid gap-3 mt-2">
                                    <Button variant={'primary'} className={'text-white'} type={'submit'} shadow={true} centered>
                                        {'Anmelden'}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </LoginWrapper>
                    <Button variant={'light'} onClick={handleHide} className={'position-absolute d-none d-sm-block top-0 end-0 my-2 mx-1 icon icon--close icon--size__md'} />
                </div>
            </div>
        </div>) : null
    )
}

export default SessionInfo
