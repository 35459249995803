// src/components/molecules/table-cell/TableCell.tsx

import { TableCellProps } from '.';

const TableCell = ({ component = 'td', children, ...props }: TableCellProps) => {
  const TableCellComponent = component;
  return <TableCellComponent {...props}>{children}</TableCellComponent>;
};

export default TableCell;
