'use client'

import type { ClientWrapperProps } from "."

const ClientWrapper = ({ children }: ClientWrapperProps) => {
    return (
        children
    )
}

export default ClientWrapper
