'use client'

import type { NewCustomerCouponInfoProps } from "."

import { useCartContext } from "@context/cart-provider"
import { useEffect, useState } from "react"

const NewCustomerCouponInfo = ({ children }: NewCustomerCouponInfoProps) => {
    const { cart } = useCartContext()

    const [showInfo, setShowInfo] = useState<boolean>(false)

    useEffect(() => {
        setShowInfo(() => {
            const items = cart?.lineItems?.filter(({ type, referencedId }: { type: string, referencedId: string }) => 'promotion' === type && 'hallo15' === referencedId.toLocaleLowerCase())
            return 0 === items?.length
        })
    }, [cart])

    return showInfo && children
}

export default NewCustomerCouponInfo
