'use client'

import { useSession } from 'next-auth/react';
import Modal from 'react-bootstrap/Modal';
import Button from '@components/atoms/button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { setCustomerGroup } from '@utils/customer-group';

export type CustomerGroupModalprops = {
    [key: string]: string
}

const CustomerGroupModal = ({ title, buttonB2C, buttonB2CSubline, buttonB2CLegal: _, buttonB2B, buttonB2BSubline, buttonB2BLegal: __ }: CustomerGroupModalprops) => {
    const { data: session, update } = useSession()

    const handleClick = (group: string) => {
        update({ group }).then(() => setCustomerGroup(group))
    }

    return (null === session?.user?.group?.displayGross && (
        <Modal size={'lg'} aria-labelledby={'modal-customer-group-select'} show={true} centered>
            <Modal.Header>
                <Modal.Title id={'modal-customer-group-select'}>
                    <span className={'fs-5  fw-semibold'}>{title}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className={'py-2'}>
                    <Row>
                        <div className={'col-md-6 col-12'}>
                            {buttonB2BSubline && <p className={'text-center  fw-semibold  mb-2'}>{buttonB2BSubline}</p>}
                            <Button variant={'secondary'} className={'w-100  rounded  py-2'} centered shadow onClick={() => handleClick('b2b')}>{buttonB2B}</Button>
                            {/* buttonB2BLegal && <p className={'mt-3  fs-9  fw-semibold'}>{buttonB2BLegal}</p> */}
                        </div>
                        <div className={'col-md-6 col-12'}>
                            {buttonB2CSubline && <p className={'text-center  fw-semibold  mb-2'}>{buttonB2CSubline}</p>}
                            <Button variant={'secondary'} className={'w-100  rounded  py-2'} centered shadow onClick={() => handleClick('b2c')}>{buttonB2C}</Button>
                            {/* buttonB2CLegal && <p className={'mt-3  fs-9  fw-semibold'}>{buttonB2CLegal}</p> */}
                        </div>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    ))
}

export default CustomerGroupModal
