'use client'

import type { FooterPaymentBoxProps } from '.';

import Link from 'next/link';
import Image from '@components/atoms/image'
import classNames from 'classnames';
import { useSession } from 'next-auth/react';

const FooterPaymentBox = ({  }: FooterPaymentBoxProps) => {
    const { data: session } = useSession()

    const PAYMENTS: { key: string, url: string, name: string, displayGross: boolean | null, type: 'png' | 'svg' }[] = [
        { key: 'rechnung', url: '/Icons/payment/ico-payment-invoice.svg', name: 'Rechnung - TransPak AG', displayGross: false, type: 'svg' },
        { key: 'paypal', url: '/Icons/payment/ico-payment-paypal.svg', name: 'PayPal - TransPak AG', displayGross: null, type: 'svg' },
        { key: 'vorkasse', url: '/Icons/payment/ico-payment-vorkasse.svg', name: 'Vorkasse - TransPak AG', displayGross: null, type: 'svg' },
        { key: 'sepa', url: '/Icons/payment/ico-payment-sepa.svg', name: 'SEPA - TransPak AG', displayGross: false, type: 'svg' },
        { key: 'mastercard', url: '/Icons/payment/ico-payment-mastercard.svg', name: 'Mastercard - TransPak AG', displayGross: null, type: 'svg' },
        { key: 'amex', url: '/Icons/payment/ico-payment-amex.png', name: 'Amex - TransPak AG', displayGross: null, type: 'png' },
        { key: 'visa', url: '/Icons/payment/ico-payment-visa.png', name: 'Visa - TransPak AG', displayGross: null, type: 'png' }
    ]

    return (
        <div className={'d-flex  justify-content-center  flex-wrap  my-4  px-0  px-lg-5  px-xl-10'}>
            {PAYMENTS.filter(({ displayGross }) => null === displayGross || (undefined !== session?.user?.group?.displayGross && session?.user?.group?.displayGross === displayGross)).map(({ key, url, name, type }) =>
                <Link key={key} href={'/zahlung-und-versand/zahlungsarten'} className={'d-inline-block  flex-grow-1  text-center'} prefetch={false}>
                    <Image src={url} alt={name} title={name} width={'png' === type ? 115 : 98} height={'png' === type ? 66 : 48} className={classNames(null, { 'rounded  bg-white  shadow-sm  p-2  my-2  mx-3': 'svg' === type })} unoptimized={'svg' === type} />
                </Link>
            )}
        </div>
    );
};

export default FooterPaymentBox;
