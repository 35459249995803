'use client'

import { LoginWrapperProps } from "."

import { useState } from "react";
import React from "react";
import { object, string } from "yup";
import { useSession } from 'next-auth/react'
import { toast } from 'react-toastify';
import Button from "@components/atoms/button";
import { handleLoginAlt } from "@app/actions";
import { useSessionContext } from "@/components/AnonymousSessionProvider";
import { sendADEvent } from "@third-parties/ads-defender";
import { useLocale } from "next-intl";

const LoginWrapper = ({ children: child }: LoginWrapperProps) => {
    const { setDisplayLoggedOutInfo } = useSessionContext()
    const { update } = useSession()
    const locale = useLocale()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const renderChild = (child: any): any => {
        if (React.isValidElement(child as any)) {
            if ('string' !== typeof child && 'number' !== typeof child && Button === child.type) {
                return React.cloneElement(child as React.ReactElement<any>, { ...child.props, loading: isLoading, disabled: isLoading })
            }

            if (Array.isArray(child.props?.children)) {
                return React.cloneElement(child as React.ReactElement<any>, { ...child.props, ...(child.props.children?.length && { children: child.props.children?.map((child: any) => renderChild(child)) }) });
            }

            return React.cloneElement(child as React.ReactElement<any>, { ...child.props, children: renderChild(child.props.children) });
        }
    }

    const handleLogin = async ({ username_loginWrapper: username, password_loginWrapper: password }: { username_loginWrapper: string, password_loginWrapper: string }) => {

        setIsLoading(true);
        const { errors, contextToken } = await handleLoginAlt({ username, password })

        if (errors) {
            errors.forEach(({ detail }: { detail: string }) => {
                toast.error(detail)
            })
            setIsLoading(false);
            return false
        }

        update({ contextToken, locale }).then(() => {
            sendADEvent()
            setDisplayLoggedOutInfo(false)
            setIsLoading(false);
        })
    };

    return (
        <>
            {React.cloneElement(child as React.ReactElement<any>, {
                ...child.props,
                children: child.props.children?.map((child: any) => renderChild(child)),
                initialValues: { username_loginWrapper: '', password_loginWrapper: '' },
                validationSchema: object().shape({
                    username_loginWrapper: string().required('Pflichtfeld'),
                    password_loginWrapper: string().required('Pflichtfeld')
                }),
                onSubmit: handleLogin
            })}
        </>
    )
}

export default LoginWrapper
