'use client';

import { useSession } from 'next-auth/react';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';

import CustomerGroupModal from '@components/organisms/header/customer-group/CustomerGroupModal';
import { setCustomerGroup } from '@utils/customer-group';

const CustomerGroupToggle = ({ labels }: any) => {
    const { data: session, update } = useSession()

    const handleClick = (group: string | null) => {
        update({ group }).then(() => setCustomerGroup(group))
    }

    return (
        <div className={'customer-group-toggle  position-relative  d-inline-flex  zindex-tooltip  justify-content-end'}>
            {session ? (true === session?.user?.anonym ? (
                <>
                    <Dropdown onSelect={(evt) => handleClick(evt)}>
                        <DropdownToggle size={'sm'} className={'d-block  text-white  border-0  fs-8  p-0 m-0  icon--white  bg-transparent'}>
                            {labels[false === session?.user?.group?.displayGross ? 'b2b' : (true === session?.user?.group?.displayGross ? 'b2c' : '')] ?? labels.CustomerGroup}
                        </DropdownToggle>
                        <DropdownMenu className={'bg-white'}>
                            <DropdownItem className={'fs-8'} key={'customergroup_b2c'} eventKey={'b2c'}>
                                {labels.b2c}
                            </DropdownItem>
                            <DropdownItem className={'fs-8'} key={'customergroup_b2b'} eventKey={'b2b'}>
                                {labels.b2b}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <CustomerGroupModal {...labels.CustomerGroupModal} />
                </>
            ) : (
                <span className={'text-white  border-0  fs-8  p-0 m-0  icon--white  bg-transparent'}>
                    {`Hallo${session?.user?.customer?.salutation.displayName ? ' ' + session?.user?.customer?.salutation.displayName : ''} ${session?.user?.customer?.salutation.displayName ? session?.user?.customer?.lastName : session?.user?.customer?.firstName}, Sie kaufen ${true === session?.user?.group?.displayGross ? 'privat' : 'gewerblich'} ein.`}
                </span>
            )) : (
                <Dropdown>
                    <DropdownToggle size={'sm'} className={'d-block  text-white  border-0  fs-8  p-0 m-0  icon--white  bg-transparent'}>
                        {labels.CustomerGroup}
                    </DropdownToggle>
                    <DropdownMenu className={'bg-white'} >
                        <DropdownItem className={'fs-8'} key={'customergroup_b2c'} eventKey={'b2c'}>
                            {labels.b2c}
                        </DropdownItem>
                        <DropdownItem className={'fs-8'} key={'customergroup_b2b'} eventKey={'b2b'}>
                            {labels.b2b}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            )}
        </div>
    )
}

export default CustomerGroupToggle
