'use client'

import type { CloseProps } from ".";

const Close = ({ className = 'd-inline-flex  icon--white' }: CloseProps) => (
    <span
        className={`icon  icon--x  cursor--pointer${className ? ' ' + className : ''}`}
        onClick={() => {
            var element = document.getElementById('all-products') as HTMLInputElement;
            if (element && true === element.checked) {
                element.click();
            }
        }}>
    </span>
)

export default Close
