'use client'

import type { ProductBadgesClientProps } from '.';
import Badge from '@components/atoms/badge';

const ProductBadgesClient = ({ markAsTopseller = false, isNew = false, isCloseout = false, isPromotion = false, tSignetIds = false, badgesWithHover = false, labels, className = '' }: ProductBadgesClientProps) => {
    const t = (str: string) => labels[str] ?? ''

    const badges: { identifier: string, variant: string, href?: string }[] = []

    if (markAsTopseller) {
        badges.push({ identifier: 'topseller', variant: 'orange-500' })
    }
    if (isNew) {
        badges.push({ identifier: 'new', variant: 'new' })
    }
    if (isCloseout) {
        badges.push({ identifier: 'closeout', variant: 'action' })
    } if (isPromotion) {
        badges.push({ identifier: 'promotion', variant: 'promotion' })
    }

    if (false !== tSignetIds) {
        if (Array.isArray(tSignetIds)) {
            var unique = Array.from(new Set(tSignetIds));
            unique.forEach((id: number | string) => badges.push({
                identifier: 'eco-' + (id ?? '1'), variant: 'eco', href: process.env.NEXT_PUBLIC_ENV_BADGE_LINK
            }))
        } else {
            badges.push({ identifier: 'eco', variant: 'eco' })
        }
    }

    return ((0 < badges.length) ? (
        <div className={className}>
            {badges.map(({ identifier, variant, href }) => (
                <Badge
                    key={`badge_${identifier}`}
                    variant={variant}
                    hasHover={badgesWithHover}
                    isRounded={badgesWithHover}
                    icon={identifier}
                    href={href}
                >
                    <span dangerouslySetInnerHTML={{ __html: t(`badge_${identifier}`) }}></span>
                </Badge>
            ))}
        </div>
    ) : (
        <></>
    ))
};

export default ProductBadgesClient;
