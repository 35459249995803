'use client'

import { cloneElement, useEffect, useRef, useState } from 'react';
import type { CollapsibleProps } from '.';
import useBreakpoint from '@hooks/useBreakpoint';

const Collapsible = ({ children }: CollapsibleProps) => {
    const [next, setNext] = useState<any | undefined>(undefined)

    const ref = useRef<HTMLLIElement>(null);

    const breakpoint = useBreakpoint()

    const handleCollapse = () => {

        ref.current?.classList.toggle('fw-normal')
        ref.current?.classList.toggle('fw-bold')
        ref.current?.children.item(1)?.classList.toggle('icon--chevron-down')

        next?.classList.toggle('d-none')
    }

    const handleClick = (a: any) => {
        a.addEventListener('click', (e: any) => {
            var element = document.getElementById('all-products') as HTMLInputElement;
            if (element && true === element.checked) {
                element.click();
            }
        })
    }

    useEffect(() => {
        if (ref?.current?.nextElementSibling) {
            if (['xs', 'sm'].includes(breakpoint)) {
                const link = ref.current.children.item(2)

                if (link && link instanceof HTMLAnchorElement) {
                    link.setAttribute('aria-disabled', 'true')
                    link.setAttribute('disabled', 'disabled')
                    link.addEventListener('click', (e: any) => e.preventDefault())
                }

                setNext(ref.current.nextSibling)
            } else if (!['xs', 'sm'].includes(breakpoint)) {
                setNext(null)
            }
        }
    }, [ref, breakpoint]);

    useEffect(() => {
        if (next?.firstChild?.children) {
            Array.from(next.firstChild.children).forEach((child: any) => {
                handleClick(child.firstChild)
            })
        }
    }, [next])

    return (
        <>{cloneElement(children, { ref: ref, ...(['xs', 'sm'].includes(breakpoint) && { onClick: handleCollapse }) })}</>
    )
}

export default Collapsible
