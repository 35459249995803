// https://stackoverflow.com/questions/69727243/react-search-using-debounce/69729166#69729166

'use client'

import { useEffect, useCallback } from 'react';

export default function useDebounce(effect: () => void, dependencies: any[], delay: number) {
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    const callback = useCallback(effect, dependencies);

    useEffect(() => {
        const timeout = setTimeout(callback, delay);
        return () => clearTimeout(timeout);
    }, [callback, delay]);
}