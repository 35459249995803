'use client'

import type { ErrorProps } from ".";
import { useCartContext } from "@context/cart-provider";
import ActionBox from "@/components/atoms/action-box";

import errorsJson from '../../../../../errors'
import { getErrors } from "@/utils/translation-faker";

const Errors = ({ }: ErrorProps) => {

    const { cart } = useCartContext()

    const t = (key: any, params?: any) => {
        let error = (errorsJson as any)[key] ?? ''
        Object.keys(params ?? {}).forEach(p => {
            const reg = new RegExp(`\\{\\{\\s*${p}\\s*\\}\\}`, 'g')
            error = error.replace(reg, params[p])
        })
        return error
    }

    let _errors: any[] = []
    if (undefined !== cart?.errors && Array.isArray(Object.keys(cart.errors))) {
         _errors = getErrors(cart.errors)
    }

    return (
        _errors ? (
            _errors.map(({ key, messageKey, message }: any) =>
                <ActionBox key={'action_' + key} variant={'promotion-discount-added' === messageKey ? 'secondary' : 'danger'} className={'my-1 py-2 align-items-start'}>
                    <span className={'d-block'}>
                        {'promotion-discount-added' !== messageKey && <span className={'d-block fw-bold w-100 text-uppercase'}>{'Achtung'}</span>}
                        <span>{message}</span>
                    </span>
                </ActionBox>
            )) : (
            null
        )
    )
}

export default Errors
