import type { BadgeProps } from '.';
import { createElement } from 'react';
import classNames from 'classnames';
import Link from '@components/atoms/link';

const Badge = ({
    children,
    icon,
    variant,
    href,
    hasHover = false,
    isRounded = false,
    ...props
}: BadgeProps) => {

    const renderBadge = (component: any, props: any, children: React.ReactNode) => createElement(component, props, children)

    return renderBadge(href ? Link : 'span', {
        ...props,
        href,
        className: classNames(`badge d-inline-flex  align-items-center`, {
            ['badge-hover']: hasHover,
            [`badge-${variant}`]: '' !== variant,
            [`icon icon--badge__${icon} icon--white`]: icon,
            'px-1 pe-2': hasHover && children,
            'ps-1 pe-2': !hasHover && children,
            'px-1': undefined == children,
            'text-decoration-none  cursor-pointer': href,
            'rounded-0': !isRounded
        })
    }, <div className={classNames('badge-text', { ['badge-hover']: hasHover, 'ms-2': !hasHover })}>{children}</div>)


};

export default Badge;
