'use client'

import type { FreeShippingProps } from ".";
import { useCartContext } from "@context/cart-provider";
import { useEffect, useState } from "react";
import ShowUpToFreeShipping from "@/components/molecules/show-up-to-free-shipping";


const FreeShipping = ({ }: FreeShippingProps) => {

    const { cart } = useCartContext()
    const [hasFreeShipping, setHasFreeShipping] = useState<boolean>(false)

    useEffect(() => {
        cart?.deliveries?.forEach(({ shippingMethod: { prices } }: { shippingMethod: { prices: any } }) => setHasFreeShipping((hasFreeShipping: boolean) => hasFreeShipping || 0 === prices.length))
    }, [cart])

    return (cart?.deliveries?.map(({ shippingMethod: { id, prices } }: { shippingMethod: { prices: any, id: string } }) => (
        <ShowUpToFreeShipping key={'up_to_free_shipping' + id} hasFreeShipping={hasFreeShipping} positionPrice={cart?.price?.positionPrice} prices={prices} labels={{
            up_to_free_shipping: 'Nur noch <strong>{ value }</strong> bis zur kostenlosen Lieferung.',
            up_to_free_shipping_present: 'Sie bestellen versandkostenfrei!',
            up_to_free_shipping_reached: 'Geschafft, Sie haben die Versandkosten eingespart!',
        }} />
    )))
}

export default FreeShipping
