'use client'

import type { RemoveFromCartButtonProps } from '.';

import { useState } from 'react';
import Button from '@components/atoms/button';
import { handleRemoveFromCart } from '@app/actions';
import FormControl from '@components/atoms/form-control';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { useCartContext } from '@context/cart-provider';
import classNames from 'classnames';
import googleSendEvent from '@helpers/google-send-event';
import { useLocale } from 'next-intl';

const RemoveFromCart = ({
    referenceId,
    productName,
    disabled,
    callBack = () => { } }: RemoveFromCartButtonProps) => {
    const [loading, setLoading] = useState(false);

    const { cart: contextCart, setCart } = useCartContext()
    const locale = useLocale()

    const onSubmitHandler = async (event: any) => {
        setLoading(true)

        event.preventDefault()
        var formData = new FormData(event.nativeEvent.target)

        await handleRemoveFromCart(formData).then((cart) => {
            if (cart.errors.length) {
                cart.errors.forEach(({ detail }: { detail: string }) => {
                    toast.error(detail)
                })
            }

            const lineItem = contextCart?.lineItems.find(({ id }: { id: string }) => formData.get('id') === id)
            if (lineItem) {
                // send google events
                googleSendEvent('remove_from_cart', {
                    value: (lineItem.priceDefinition.price / (lineItem.payload.purchaseUnit ?? 1)) ?? null,
                    currency: 'de-CH' === locale ? 'CHF' : 'EUR',
                    items: [{ item_id: lineItem.payload.productNumber, item_name: lineItem.label, quantity: lineItem.quantity * (lineItem.payload.purchaseUnit ?? lineItem.priceDefinition?.referencePriceDefinition?.purchaseUnit ?? 1) }]
                })
            }

            setCart(cart)
            setLoading(false)

            callBack()
        });
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <FormControl id={'id'} name={'id'} type={'hidden'} defaultValue={referenceId} controlled={false} />
            <Button
                className={classNames('d-inline-flex  justify-content-center  align-items-center  p-2  m-0', {
                    'icon  icon--trash': false === loading,
                })}
                variant={'outline-secondary border'}
                disabled={loading || disabled}
                aria-label={`${productName} aus dem Warenkorb entfernen.`}
                type={'submit'}
            >
                {loading && <Spinner size={'sm'} />}
            </Button>
        </form>
    );
};

export default RemoveFromCart;
