import Cookies from 'js-cookie'
import { SHOPWARE_COOKIE_EXPIRE, SHOPWARE_CUSTOMER_GROUP_COOKIE } from '@/const'

export const getCustomerGroup = () => Cookies.get(SHOPWARE_CUSTOMER_GROUP_COOKIE)

export const setCustomerGroup = ( token: string | null) => {
    if (!token) {
        Cookies.remove(SHOPWARE_CUSTOMER_GROUP_COOKIE)
    } else {
        Cookies.set(
            SHOPWARE_CUSTOMER_GROUP_COOKIE,
            token,
            {
                expires: SHOPWARE_COOKIE_EXPIRE,
                sameSite: 'strict'
            }
        )
    }
}